import { Box, useTheme, useMediaQuery } from '@mui/material';
import React from 'react'

export const Seguros = () => {

const srcSeguros = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/edith_seguro.png'

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box>
        {isSmallScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBottom: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <img
                        src={srcSeguros}
                        style={{width: '100%'}}
                    />
                </Box>
            </Box>
        }
        {isMediumScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBottom: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <img
                        src={srcSeguros}
                        style={{width: '80%'}}
                    />
                </Box>
            </Box>
        }
        {isLargeScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBottom: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <img
                        src={srcSeguros}
                        style={{width: '60%'}}
                    />
                </Box>
            </Box>
        }
        {isExtraLargeScreen &&
            <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '100%', paddingBottom: '2vh'}}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <img
                        src={srcSeguros}
                        style={{width: '60%'}}
                    />
                </Box>
            </Box>
        }
    </Box>
  )
}
