import React from 'react'
import { Box, Button, Container, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

export const Contactanos = () => {
  return (
    <Box sx={{backgroundColor: '#F5F5F5', width: '100%', height: '90vh', backgroundSize: "cover"}}>    
        <Box display={'flow-root'}>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{display: 'flex', flexWrap: 'wrap', width: '90%', marginTop: '20vh'}}>
                    {/* BOX 1 */}
                    <Container sx={{display: 'flex', flexDirection: 'column', width: '670px'}}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography color='primary' sx={{fontSize: 45, fontWeight: '700', textAlign: 'center', textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}>
                                ¿Tienes alguna duda? 
                                ¡Contactanos!
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography sx={{
                                    fontSize: 23, 
                                    color: '#757575', 
                                    width: '100%',
                                    textAlign: 'justify'
                                }}
                            >
                                En Sindicato por México trabajaremos 
                                para resolver tu duda lo más pronto posible
                            </Typography>
                        </Box>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <figure>
                                <img
                                    src={src_url + 'logo_spm.png'}
                                />
                            </figure>
                        </Box>
                    </Container>
                    {/* BOX 2 */}
                    <Container sx={{display: 'flex', flexDirection: 'column', width: '670px'}}>
                        <Container sx={{borderRadius: 8, background: '#E5E5E5', marginBlock: '3vh'}}>
                            <TextField
                                type='text'
                                color='primary' 
                                label='Nombre(s)'
                                variant='filled'
                                sx={{width: '100%', marginBottom: '4%', marginTop: '4%'}}
                            />
                            <TextField
                                type='email'
                                color='primary' 
                                label='Correo electrónico'
                                variant='filled'
                                sx={{width: '100%', marginBottom: '4%'}}
                            />
                            <TextField
                                type='text'
                                color='primary' 
                                label='Número telefónico'
                                variant='filled'
                                sx={{width: '100%', marginBottom: '4%'}}
                            />
                            <TextField
                                type='text'
                                color='primary'
                                label="Tu mensaje"
                                variant="filled"
                                sx={{width: '100%', marginBottom: '4%'}}
                                defaultValue="Escribe tu mensaje"
                                multiline
                                rows={4}
                            />
                            <Box sx={{display: 'flex', justifyContent: 'end', marginBottom: '4%'}}>
                                <Link to='/preAfiliacion' style={{textDecoration: 'none'}}>
                                    <Button color='primary' variant="contained" sx={{borderRadius: 8}}>
                                        <Typography sx={{fontSize: 21, fontWeight: 700}} style={{color: 'white'}}>
                                            ENVIAR
                                        </Typography>
                                    </Button>
                                </Link>
                            </Box>
                        </Container>
                    </Container>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}
