import React from 'react';
import { Document, Page, View, Text, Image, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: 'rgba(255, 255, 255, 0.6)', // Fondo semitransparente para el contenido
  },
  id: {
    position: 'absolute',
    fontWeight: '700', 
    fontSize: 15, 
    color: '#303030', 
    marginTop: '10%',
    marginLeft: '73%'
  },
  va: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    marginTop: '32%',
  },
  avatar: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '29%',
    width: '122px',
    height: '142px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  vf: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    marginTop: '20%',
  },
  firma: {
    position: 'absolute',
    // fontWeight: '700', 
    // fontSize: 17, 
    // color: 'black', 
    
    // marginTop: '30%',
    marginLeft: '25%',
    
    display: 'flex',
    justifyContent: 'center',
    width: '55%',
    // height: '50px'
  },
  text: {
    position: 'absolute',
    fontWeight: '700', 
    fontSize: 17, 
    // color: '#991f1f', 
    color: '#312E2F', 
    marginTop: '18%',
    marginLeft: '57%'
  }
});


export const CredencialPDF = (props) => {
  return (
    <Document>
        <Page size='A5' orientation='landscape'>
            <View style={styles.page}>
              <Image 
                style={styles.image}
                src='..\..\img\afiliacion\credencial.png' 
              />
              <View style={styles.content}>
                {/* ID */}
                <Text style={styles.id}>
                  SPM-{props.id}
                </Text>
                {/* AVATAR BASE64 */}
                <View style={styles.va}>
                  <Image
                    style={styles.avatar}
                    src={props.avatar} 
                  />
                </View>
                {/* FIRMA BASE64 */}
                <View style={styles.vf}>
                  <Image 
                    style={styles.firma}
                    src={props.signature}
                  />  
                </View>
                {/* NOMBRE */}
                <Text style={styles.text}>
                  {props.name}
                </Text>
              </View>
            </View>
        </Page>
    </Document>
  )
}
