import { Box, Container, TextField, Typography, Button, useTheme, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { guardaAfiliado, getToken, getUserByEmailPass, getUserByEmailRecoverPass, emailAutomaticoRecoverPass } from '../../../utils/xmex/services';
import { Btn_afiliarse } from '../afiliacion/Btn_afiliarse';
import { NavBar_Login } from './NavBar_Login';

export const BoxLogin = () => {  
      
  // URL DEL SERVIDOR PARA IMAGENES
  const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

  // Responsive
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const [isPasswordReset, setIsPasswordReset] = useState(false);

  // Manejador para cambiar a la vista de recuperación de contraseña
  const handlePasswordResetClick = () => {
    setIsPasswordReset(true);
  };

  // Manejador para volver a la vista de inicio de sesión
  const handleBackToLoginClick = () => {
    setIsPasswordReset(false);
  };

  const [jsonCredenciales, setJsonCredenciales] = useState({
    email:'',
    password: ''
  });

  const [user, setUser] = useState([]);

  // CHANGE HANDLERS
  const emailAfiliadoChangeHandler =(event) => {
    setJsonCredenciales((prevState) => {
      return {...prevState, email: event.target.value};
    });
  };
  const passwordAfiliadoChangeHandler =(event) => {
    setJsonCredenciales((prevState) => {
      return {...prevState, password: event.target.value};
    });
  };

  // mensaje de error
  const [errorMsg, setErrorMsg] = useState('');
  // color de mensaje 
  const [colorMsg, setColorMsg] = useState('');
  // useNavigate hook
  const navigate = useNavigate();
// login
const validateForm = () => {
  if (jsonCredenciales.email !== '' && jsonCredenciales.password !== '') {
      console.log('Validando el formulario...');
      console.log('email: ' + jsonCredenciales.email);
      console.log('pass: ' + jsonCredenciales.password);

      getUserByEmailPass(jsonCredenciales.email, jsonCredenciales.password).then((response) => {
          if (response.status === 200 && response.data) {
              // El login fue exitoso, redirigir
              if ( response.data )
              setUser(response.data);
              navigate('/'+(response.url));
          } else if (response.status === 401) {
              // Error de autenticación (por ejemplo, credenciales incorrectas)
              setColorMsg('red');
              setErrorMsg('Email o password incorrecto.');
            } else if (response.status === 500) {
              // Error en el servidor o conexión
              setColorMsg('red');
              setErrorMsg('Email o password incorrecto.');
          } else {
              // Cualquier otro tipo de error
              setErrorMsg('Ocurrió un error inesperado.');
              setColorMsg('red');
          }
      }).catch((error) => {
          console.error('Error en la validación:', error);
          setErrorMsg('Hubo un problema, intenta nuevamente.');
          setColorMsg('red');
      });
  } else {
      setErrorMsg('Por favor ingresa email y contraseña.');
      setColorMsg('red');
  }
};

const validateRecoverPass = () => {
  if (jsonCredenciales.email !== '') {
    getUserByEmailRecoverPass(jsonCredenciales.email).then((response) => {
      if (response.status === 200 && response.data) {
        // El login fue exitoso, redirigir
        emailAutomaticoRecoverPass({
          usuario: {
            id: response.data.id,
            nombre: response.data.nombre,
            email: response.data.email
          }
        });
        setErrorMsg('Se ha enviado un correo.');
        setColorMsg('green');
      } else {
        // Cualquier otro tipo de error
        setErrorMsg('No estas registrado.');
        setColorMsg('red');
      }
    })
  }
};

  return (
    <div>
      <NavBar_Login/>
      {isSmallScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "250% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '85%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='email'
                  label='Correo electrónico' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '100%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  onChange={emailAfiliadoChangeHandler}
                />
                <TextField 
                  type='password'
                  label='Contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  <Link to={'/preAfiliacion'}>
                    <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                      <Typography>
                        Afiliarse
                      </Typography>
                    </Button>
                  </Link>
                  
                  {/* BTN ¿Olvidaste tu contraseña? */}
                  <Button 
                    variant="text"
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'none' : 'true' }}
                    onClick={handlePasswordResetClick}
                  >
                    <Typography>
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </Button>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Iniciar
                    </Typography>
                  </Button>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'true' : 'none' }}
                    onClick={validateRecoverPass}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Recuperar
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isMediumScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "200% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '55%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='email'
                  label='Correo electrónico' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '100%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  onChange={emailAfiliadoChangeHandler}
                />
                <TextField 
                  type='password'
                  label='Contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  <Link to={'/preAfiliacion'}>
                    <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                      <Typography>
                        Afiliarse
                      </Typography>
                    </Button>
                  </Link>
                  
                  {/* BTN ¿Olvidaste tu contraseña? */}
                  <Button 
                    variant="text"
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'none' : 'true' }}
                    onClick={handlePasswordResetClick}
                  >
                    <Typography>
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </Button>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Iniciar
                    </Typography>
                  </Button>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'true' : 'none' }}
                    onClick={validateRecoverPass}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Recuperar
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isLargeScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "150% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '45%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='email'
                  label='Correo electrónico' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '100%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  onChange={emailAfiliadoChangeHandler}
                />
                <TextField 
                  type='password'
                  label='Contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  <Link to={'/preAfiliacion'}>
                    <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                      <Typography>
                        Afiliarse
                      </Typography>
                    </Button>
                  </Link>
                  {/* BTN ¿Olvidaste tu contraseña? */}
                  <Button 
                    variant="text"
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'none' : 'true' }}
                    onClick={handlePasswordResetClick}
                  >
                    <Typography>
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </Button>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Iniciar
                    </Typography>
                  </Button>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'true' : 'none' }}
                    onClick={validateRecoverPass}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Recuperar
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
      {isExtraLargeScreen &&
        <Box sx={{position: 'relative', height: '100vh'}}>
          <Box 
            id={'inicio'}
            style={{
              backgroundImage: "url(https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/slider/xmex_gif.gif)", 
              width: "100%", 
              height: "100%", 
              backgroundSize: "100% 100%",
              backgroundPosition: 'center',
              position: 'absolute'
            }}
          />
          <Box sx={{position: 'relative', zIndex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'}}>
            <Box sx={{width: '35%', background: 'rgba(217, 217, 217, 0.8)', boxShadow: 7, borderRadius: 8}}>
              <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <figure>
                  <img 
                    src={src_url + 'logo_spm.png'} 
                    style={{width: '10ch'}}
                  />
                </figure>
              </Box>
              <Container sx={{marginBottom: '5%'}}>
                <Typography sx={{ display: isPasswordReset ? 'block' : 'none' }}>
                  Introduce tu correo para recuperar tu contraseña
                </Typography>
                <TextField 
                  type='email'
                  label='Correo electrónico' 
                  color='primary' 
                  variant='filled'
                  sx={{width: '100%', marginBottom: '4%'}}
                  inputProps={{maxLength: 40}}
                  onChange={emailAfiliadoChangeHandler}
                />
                <TextField 
                  type='password'
                  label='Contraseña' 
                  color='primary' 
                  variant='filled' 
                  onChange={passwordAfiliadoChangeHandler}
                  sx={{ width: '100%', marginBottom: '4%', display: isPasswordReset ? 'none' : 'true' }}
                />
                <Typography style={{color: colorMsg}}>
                  {errorMsg}
                </Typography>
              </Container>
              {/* BTN's */}
              <Container sx={{display: 'flex', flexDirection: 'row', marginBottom: '5%'}}>
                {/* BTN  */}
                <Box sx={{flexGrow: 1}}>
                  <Link to={'/preAfiliacion'}>
                    <Button variant="text" sx={{color: '#505050', textTransform: 'none'}}>
                      <Typography>
                        Afiliarse
                      </Typography>
                    </Button>
                  </Link>
                  {/* BTN ¿Olvidaste tu contraseña? */}
                  <Button 
                    variant="text"
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'none' : 'true' }}
                    onClick={handlePasswordResetClick}
                  >
                    <Typography>
                      ¿Olvidaste tu contraseña?
                    </Typography>
                  </Button>
                  {/* BTN Iniciar sesión */}
                  <Button 
                    variant="text" 
                    sx={{ color: '#505050', textTransform: 'none', display: isPasswordReset ? 'true' : 'none' }}
                    onClick={handleBackToLoginClick}
                  >
                    <Typography>
                      Iniciar sesión
                    </Typography>
                  </Button>
                </Box>
                {/* BTN INICIAR SESION */}
                <Box>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'none' : 'true' }}
                    onClick={validateForm}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Iniciar
                    </Typography>
                  </Button>
                  <Button
                    color='primary' 
                    variant='contained'
                    sx={{ borderRadius: 8, display: isPasswordReset ? 'true' : 'none' }}
                    onClick={validateRecoverPass}
                    // disabled={props.disabled}
                  >
                    <Typography
                      sx={{fontSize: 18, fontWeight: 500}} 
                      style={{color: 'white'}}
                    >
                      Recuperar
                    </Typography>
                  </Button>
                </Box>
              </Container>
            </Box>
          </Box>
        </Box>
      }
    </div>
  )
}
