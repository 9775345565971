import React from 'react'
import { BoxRecuperarPass } from '../../components/xmex/login/RecuperarPass'

export const RecuperarPass = () => {
  return (
    <div>
        <BoxRecuperarPass/>  
    </div>
  )
}
