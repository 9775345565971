import React, { Fragment } from 'react'
import { AppBar, Toolbar, Box, useTheme, useMediaQuery } from '@mui/material';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

export const NavBar_Login = () => {
    
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Fragment>
      {isSmallScreen &&
            <AppBar
                position='absolute'
                sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '6ch'}}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isMediumScreen &&
            <AppBar
                position='absolute'
                sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '7ch'}}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isLargeScreen &&
            <AppBar
                position='absolute'
                sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '7ch'}}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        }
        {isExtraLargeScreen &&
            <AppBar
                position='absolute'
                sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}
            >
                <Toolbar>
                    <Box sx={{flexGrow: 1}}>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '7ch'}}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        }
    </Fragment>
  )
}
