import { Grid, Box, Button, Typography, Paper, Container, TextField, FormControl, InputLabel, Select, MenuItem, styled } from '@mui/material'
import React, {useState} from 'react'
import { MenuAdmin } from './MenuAdmin'
import * as XLSX from 'xlsx';
import { forEachEmpleados, guardaJsonEmpleados, forEachEmpleadosParaCalculo } from '../../../utils/xmex/services';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { ReporteNominaPDF } from './ReporteNominaPDF';
import DownloadIcon from '@mui/icons-material/Download';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { JsonToExcel } from './JsonToExcel';


export const Nomina = () => {

  const [jsonDataExcel, setJsonDataExcel] = useState([]);

  const [jsonApoyoSindical, setJsonApoyoSindical] = useState({
    isrEmpleado: 0,
    imssEmpleado: 0,
    impuestoSobreNomina: 0,
    imssEmpresa: 0,
    rcv: 0,
    infonavit: 0,
    total: 0
  })
  const [jsonAhorro, setJsonAhorro] = useState({
    isrEmpleado: 0,
    imssEmpleado: 0,
    impuestoSobreNomina: 0,
    imssEmpresa: 0,
    rcv: 0,
    infonavit: 0,
    total: 0,
    totalFloat: 0
  })
  const [jsonEmpresaData, setJsonEmpresaData] = useState({
    nameEmpresa: '',  
    tipoPeriodo: '',
    periodoFiscal: '',
    // tipoNomina: '',
    ano: '',
    esquema: '',
    planSindical: '',
    baseCalculo: '',
    primaRiesgo: 0.0054355,
    uma: 108.57,
    disBtnReporte: true,
    
    sumatoriaSat: 0,
    sumatoriaImss: 0,
    sumatoriaImpNomina: 0,
    sumatoriaImssEmpresa: 0,
    sumatoriaIsr: 0,
    sumatoriaSueldos: 0,
    sumatoriaSueldosBase: 0,
    sumatoriaRcv: 0,
    sumatoriaInfonavit: 0,
    sumatoriaCTA: 0,
    
    sumatoriaCTApoyoSindicalImss: 0,
    sumatoriaCTApoyoSindicalImssEmpresa: 0,
    sumatoriaCTApoyoSindicalSat: 0,
    sumatoriaCTApoyoSindicalIsr: 0,
    sumatoriaCTApoyoSindicalRcv: 0,
    sumatoriaCTAS: 0,

    restaIsr: 0,
    restaImss: 0,
    restaImpuestoNomina: 0,
    restaImssEmpresa: 0,
    restaRcv: 0,
    restaInfonavit: 0,
    restaTotal: 0,

    sueldoBaseNaranjaPorcentajeEmpresaLey: 0,
    indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato: 0,
    resultadoPorcentajeEmpresa: 0,
    resultadoPorcentajeSindicato: 0,
    costoOperacion: '',
    costoOperacionMensual: '',
    costoOperacionAnual: '',

    ahorroReal: '',
    ahorroRealAnual: ''
  });

  const nameEmpresaChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        nameEmpresa: event.target.value
      };
    });
    console.log('nameEmpresa: ' + jsonEmpresaData.nameEmpresa);
  };
  const tipoPeriodoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        tipoPeriodo: event.target.value
      };
    });
    console.log('tipoPeriodo: ' + event.target.value);
    console.log('tipoPeriodo JSON: ' + jsonEmpresaData.tipoPeriodo);
  };
  const periodoFiscalChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState, 
        periodoFiscal: event.target.value
      };
    });
    console.log('periodoFiscal: ' + event.target.value);
    console.log('periodoFiscal JSON: ' + jsonEmpresaData.periodoFiscal);
  };
  const esquemaChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        esquema: event.target.value
      };
    });
    console.log('esquema: ' + event.target.value);
  };
  const planSindicalChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        planSindical: event.target.value
      };
    });
    console.log('planSindical: ' + event.target.value);
  };
  const baseCalculoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        baseCalculo: event.target.value
      };
    });
    console.log('baseCalculo: ' + event.target.value);
  };
  const primaRiesgoChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        primaRiesgo: event.target.value
      };
    });
  };
  const costoOperacionChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        costoOperacion: event.target.value
      };
    });
  };
  const umaChangeHandler = (event) => {
    setJsonEmpresaData((prevState) => {
      return{...prevState,
        uma: event.target.value
      };
    });
  };

  const isXsScreen = window.innerWidth < 600; // Cambia el valor según el tamaño de pantalla que consideres como xs
  
  const [file, setFile] = useState(null)
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };
  
  const handleUploadClick = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const userData = XLSX.utils.sheet_to_json(sheet);
  
        // Llama a la función forEachEmpleadosParaCalculo para realizar el POST
        const {
          ley,
          sindicato,
          
          totalSat, 
          totalImss,
          totalImpNomina,
          totalImssEmpresa,     
          totalRcv,
          totalInfonavit,
          ctatotal,

          totalIsr,             
          totalSueldos, 
          totalSueldosBase,

          psIsrEmpleado,
          psImssEmpleado,
          psImpuestoNomina,
          psImssEmpresa,
          psRcv,
          psInfonavit,
          psTotal,
          
          restaIsrEmpleado,
          restaImssEmpleado,
          restaImpuestoNomina,
          restaImssEmpresa,
          restaRcv,
          restaInfonavit,

          disabledBtnResponse,

          datosParaExcel
        } = await forEachEmpleadosParaCalculo(userData, jsonEmpresaData);
        console.log('resta1:', {restaIsrEmpleado});
        console.log('resta12:', {restaImssEmpleado});
        console.log('resta13:', {restaImpuestoNomina});
        console.log('resta14:', {restaImssEmpresa});
        console.log('resta15:', {restaRcv});
        console.log('resta16:', {restaInfonavit});
  
        console.log('SUMATORIA DESPUÉS DE forEach totalImss:', {totalImss});
        console.log('SUMATORIA DESPUÉS DE forEach totalImssEmpresa:', {totalImssEmpresa});
        console.log('SUMATORIA DESPUÉS DE forEach totalSat:', {totalSat});
        console.log('SUMATORIA DESPUÉS DE forEach totalIsr:', {totalIsr});
        console.log('SUMATORIA DESPUÉS DE forEach totalSueldos:', {totalSueldos});
        console.log('SUMATORIA DESPUÉS DE forEach totalRcv:', {totalRcv});
        
        // resta total ahorro
        const totalAhorro = ctatotal - psTotal;
        console.log('totalAhorro: ' + totalAhorro);
        // totalCTA es el total negro
        // var totalCTA = totalSat + totalImss + totalImssEmpresa + totalRcv + totalRcv + (totalSueldosBase * .03);
        var totalCTA = ctatotal;
        // total naranja
        var totalCTAS = psTotal;
        console.log('totalCTA: ', {totalCTA});
        console.log('SUMATORIA DESPUÉS DE forEach totalRcv: ', {totalCTA});
        console.log('SUMATORIA DESPUÉS DE forEach totalRcv: ' + totalCTA);
        console.log('responsedisabledBtnResponseNomina: ' + disabledBtnResponse);

        setJsonDataExcel(datosParaExcel);
  
        // Actualiza el estado con la sumatoria de sueldos
        setJsonEmpresaData(prevData => ({
          ...prevData,
          sueldoBaseNaranjaPorcentajeEmpresaLey: ley.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato: sindicato.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

          sumatoriaSat: totalSat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaImss: totalImss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaImpNomina: totalImpNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          // sumatoriaImpNomina: (totalSueldosBase * .03).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaImssEmpresa: totalImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaRcv: totalRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaInfonavit: totalInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          // revisar: totalSueldos
          // sumatoriaSueldos: totalSueldos.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaIsr: totalIsr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaSueldos: (ley + sindicato).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          sumatoriaSueldosBase: totalSueldosBase.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

          sumatoriaCTA: ctatotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          disBtnReporte: disabledBtnResponse
        }));
        // Actualiza el JSON de apoyo sindical
        setJsonApoyoSindical(prevData => ({
          ...prevData,
          isrEmpleado: psIsrEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpleado: psImssEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          impuestoSobreNomina: psImpuestoNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpresa: psImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          rcv: psRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          infonavit: psInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          // total: (psIsrEmpleado + psImssEmpleado + psImpuestoNomina + psImssEmpresa + psRcv + psInfonavit).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          total: psTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }));
        setJsonAhorro(prevState => ({
          ...prevState,
          isrEmpleado: restaIsrEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpleado: restaImssEmpleado.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          impuestoSobreNomina: restaImpuestoNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          imssEmpresa: restaImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          rcv: restaRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          infonavit: restaInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          total: totalAhorro.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
          totalFloat: totalAhorro
        }));
        // console.log('totalFloat: ' + jsonAhorro.total);
        // console.log('totalFloat: ' + jsonAhorro.total - 10);
        // console.log('totalFloat: ' + jsonAhorro.totalFloat);
        // console.log('totalFloat: ' + jsonAhorro.totalFloat - 10);

        console.log('verde sumatoriaCTA : ' + jsonEmpresaData.sumatoriaCTAS);
        console.log('verde sumatoriaCTA : ' + jsonEmpresaData.sumatoriaCTA);
        console.log('verde stotal: ' + jsonApoyoSindical.total);
        console.log('psImpuestoNomina: ' + psImpuestoNomina);
        console.log('psImssEmpresa: ' + psImssEmpresa);
        console.log('psRcv: ' + psRcv);
        console.log('psInfonavit: ' + psInfonavit);
        // console.log('JSPN psIsrEmpleado: ' + jsonApoyoSindical.);
        // console.log('JSPN psImssEmpleado: ' + psImssEmpleado);
        // console.log('JSPN psImpuestoNomina: ' + psImpuestoNomina);
        // console.log('JSPN psImssEmpresa: ' + psImssEmpresa);
        // console.log('JSPN psRcv: ' + psRcv);
        // console.log('JSPN psInfonavit: ' + psInfonavit);


        // Lógica de cálculo específico según la opción seleccionada
        switch (jsonEmpresaData.planSindical) {
          case 'Empresa 60% - Sindicato 40%':
          const {costoOperacionMensual, costoOperacionAnual} = await calculoEspecifico(totalSueldos, 0.6, 0.4, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
          // Ahorro real
          // const ahorroTributario = parseFloat(jsonAhorro.total.replace(',', ''));
          const ahorroReal = (totalAhorro - costoOperacionMensual);
            console.log('ahorroReal: ' + ahorroReal);
            console.log('ahorroReal: ' + jsonAhorro.totalFloat);
            console.log('ahorroReal: ' + jsonAhorro.total);
            console.log('ahorroReal: ' + totalAhorro);
            console.log('ahorroReal: ' + costoOperacionMensual);
          // Ahorro real ANUAL
          const ahorroRealAnual = (ahorroReal * 12)
            console.log('ahorroReal ahorroRealAnual: ' + ahorroReal);
            console.log('ahorroReal ahorroRealAnual: ' + ahorroRealAnual);

          setJsonEmpresaData(prevState => ({
            ...prevState,
            ahorroReal: ahorroReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
            ahorroRealAnual: ahorroRealAnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
          }));

          break;
          case 'Empresa 70% - Sindicato 30%':
            await calculoEspecifico(totalSueldos, 0.7, 0.3, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
          break;
          case 'Empresa 80% - Sindicato 20%':
            await calculoEspecifico(totalSueldos, 0.8, 0.2, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
          break;
          case 'Empresa 90% - Sindicato 10%':
            await calculoEspecifico(totalSueldos, 0.9, 0.1, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal);
          break;
          default:
          break;
        }  
        console.log(`SUMATORIA DESPUÉS DE setJsonEmpresaData:` + jsonEmpresaData.sumatoriaSueldos);
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const calculoEspecifico = async (sumatoriaSueldos, porcentajeEmpresa, porcentajeSindicato, sindicato, totalImss, totalImssEmpresa, totalSat, totalIsr, totalRcv, ctatotal) => {
    // Calculos en base a la distribucion de calculo
    const resultadoPorcentajeEmpresa = sumatoriaSueldos * porcentajeEmpresa;
    console.log(`Resultado del porcentajeEmpresa: ${resultadoPorcentajeEmpresa}`);
    const resultadoPorcentajeSindicato = sumatoriaSueldos * porcentajeSindicato;
    console.log(`Resultado del porcentajeSindicato: ${resultadoPorcentajeSindicato}`);
    const resultadoPorcentajeImss = totalImss * porcentajeEmpresa;
    console.log(`resultadoPorcentajeImss: ${resultadoPorcentajeImss}`);
    const resultadoPorcentajeImssEmpresa = totalImssEmpresa * porcentajeEmpresa;
    console.log(`resultadoPorcentajeImssEmpresa: ${resultadoPorcentajeImssEmpresa}`);
    const resultadoPorcentajeSat = totalSat * porcentajeEmpresa;
    console.log(`resultadoPorcentajeSat: ${resultadoPorcentajeSat}`);
    const resultadoPorcentajeIsr = totalIsr * porcentajeEmpresa;
    console.log(`resultadoPorcentajeIsr: ${resultadoPorcentajeIsr}`);
    const resultadoPorcentajeRcv = totalRcv * porcentajeEmpresa;
    console.log(`resultadoPorcentajeRcv: ${resultadoPorcentajeRcv}`);
    // TOTAL negro
    // var sumatoriaCTAS = resultadoPorcentajeImss + resultadoPorcentajeImssEmpresa + resultadoPorcentajeSat + resultadoPorcentajeIsr + resultadoPorcentajeRcv + resultadoPorcentajeRcv;
    console.log('jsonEmpresaData.sumatoriaCTApoyoSindicalImss: ' + jsonEmpresaData.sumatoriaCTApoyoSindicalImss);
    console.log('jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa: ' + jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa);
    console.log('jsonEmpresaData.sumatoriaCTApoyoSindicalSat: ' + jsonEmpresaData.sumatoriaCTApoyoSindicalSat);
    console.log('jsonEmpresaData.sumatoriaIsr: ' + jsonEmpresaData.sumatoriaIsr);
    console.log('jsonEmpresaData.sumatoriaRcv: ' + jsonEmpresaData.sumatoriaRcv);
    var sumatoriaCTAS = (jsonEmpresaData.sumatoriaCTApoyoSindicalImss + jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa + jsonEmpresaData.sumatoriaCTApoyoSindicalSat + jsonEmpresaData.sumatoriaIsr + jsonEmpresaData.sumatoriaRcv + jsonEmpresaData.sumatoriaInfonavit);
    // CALCULO DE AHORRO (RESTAS)
    console.log('jsonEmpresaData.sumatoriaImss: ' + jsonEmpresaData.sumatoriaImss);
    var restaIsr = (jsonEmpresaData.sumatoriaSat - jsonApoyoSindical.isrEmpleado);
    var restaImss = jsonEmpresaData.sumatoriaImss - jsonApoyoSindical.imssEmpleado;
    var restaImpuestoNomina = jsonEmpresaData.sumatoriaImpNomina - jsonApoyoSindical.impuestoSobreNomina;
    var restaImssEmpresa = jsonEmpresaData.sumatoriaImssEmpresa - jsonApoyoSindical.imssEmpresa;
    var restaRcv = jsonEmpresaData.sumatoriaRcv - jsonApoyoSindical.rcv;
    var restaInfonavit = jsonEmpresaData.sumatoriaInfonavit - jsonApoyoSindical.infonavit;
    var restaTotal = jsonEmpresaData.sumatoriaCTAS - jsonApoyoSindical.total;
    console.log('RrestaIsr: ' + restaIsr + '  =  ' + jsonEmpresaData.sumatoriaSat + '  -  ' + jsonApoyoSindical.isrEmpleado);
    console.log('RrestaImss: ' + restaImss + '  =  ' + jsonEmpresaData.sumatoriaImss + '  -  ' + jsonApoyoSindical.imssEmpleado);
    console.log('RrestaImpuestoNomina: ' + restaImpuestoNomina + '  =  ' + jsonEmpresaData.sumatoriaImpNomina + '  -  ' + jsonApoyoSindical.impuestoSobreNomina);
    console.log('RrestaImssEmpresa: ' + restaImssEmpresa + '  =  ' + jsonEmpresaData.sumatoriaImssEmpresa + '  -  ' + jsonApoyoSindical.imssEmpresa);
    console.log('RrestaRcv: ' + restaRcv + '  =  ' + jsonEmpresaData.sumatoriaRcv + '  -  ' + jsonApoyoSindical.rcv);
    console.log('RrestaInfonavit: ' + restaInfonavit + '  =  ' + jsonEmpresaData.sumatoriaRcv + '  -  ' + jsonApoyoSindical.infonavit);
    console.log('RrestaTotal: ' + restaTotal + '  =  ' + jsonEmpresaData.sumatoriaCTAS + '  -  ' + jsonApoyoSindical.total);
    
    // Calculo de costo de operacion MENSUAL
    const costoOperacionMensual = (sindicato * jsonEmpresaData.costoOperacion)/100;
      console.log('costoOperacionMensual: ' + costoOperacionMensual);
      console.log('costoOperacionMensual: ' + jsonEmpresaData.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato);
      console.log('costoOperacionMensual: ' + jsonEmpresaData.costoOperacion);
    // Calculo de costo de operacion ANUAL
    const costoOperacionAnual = (costoOperacionMensual * 12);

    setJsonEmpresaData(prevData => ({
      ...prevData,
        resultadoPorcentajeEmpresa : resultadoPorcentajeEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        resultadoPorcentajeSindicato: resultadoPorcentajeSindicato.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

        sumatoriaCTApoyoSindicalImss: resultadoPorcentajeImss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalImssEmpresa:resultadoPorcentajeImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalSat: resultadoPorcentajeSat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalIsr: resultadoPorcentajeIsr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTApoyoSindicalRcv: resultadoPorcentajeRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        sumatoriaCTAS: sumatoriaCTAS.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

        restaIsr: restaIsr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaImss: restaImss.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaImpuestoNomina: restaImpuestoNomina.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaImssEmpresa: restaImssEmpresa.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaRcv: restaRcv.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaInfonavit: restaInfonavit.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        restaTotal: restaTotal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),

        costoOperacionMensual: costoOperacionMensual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        costoOperacionAnual: costoOperacionAnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        
        // ahorroReal: ahorroReal.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        // ahorroRealAnual: ahorroRealAnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      }));
      
    return{
      costoOperacionMensual,
      costoOperacionAnual
    }
  };
  
  // Para el boton de cargar archivos
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  // Para habilitar botones
  var disabledCargarExcel = true;
  if (((jsonEmpresaData.nameEmpresa != "") & (jsonEmpresaData.baseCalculo != "") &
  (jsonEmpresaData.f_riesgo != "") & (jsonEmpresaData.costoOperacion != "") &
  (jsonEmpresaData.periodoFiscal != "") & (jsonEmpresaData.tipoPeriodo != "") &
  (jsonEmpresaData.planSindical != "") & (jsonEmpresaData.esquema != ""))
  ){
    disabledCargarExcel = false;
  }else{
    disabledCargarExcel = true;
  }

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={2}>
        <MenuAdmin isXsScreen={isXsScreen}/>
      </Grid>
      <Grid item xs={12} sm={10} sx={{display: 'flex', justifyContent: 'center', marginTop: '2%'}}>
        <Box sx={{width: '95%', height: '90vh'}}>
          <Paper elevation={3} sx={{width: '100%', height: '100%', borderRadius: '14px'}}>
            <Box sx={{display: 'flex', alignItems: 'center', width: '100%', height: '7%', backgroundColor: '#FF8030', borderRadius: '14px'}}>
              <Typography variant="h5" sx={{color:'#FFFFFF', fontWeight: '700',  marginLeft: '2%', fontSize:{xs: '.7rem', sm: '1.2rem', md: '1.5rem' }}}>
                CALCULO DE NOMINA
              </Typography>
            </Box>
            <Container sx={{display: 'flex', marginTop: '5%'}}>
              <Box sx={{width: '100%', marginlock: '2%',  alignItems: 'center'}}>
                {/* <TextField 
                  type='text'
                  label='Días del mes' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginRight: '1.333%'}}
                />
                <TextField 
                  type='text'
                  label='Periodo de pago' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginRight: '1.333%'}}
                />
                <TextField 
                  type='text'
                  label='Días aguinaldo' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginRight: '1.333%'}}
                />
                <TextField 
                  type='text'
                  label='Días de vacaciones' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%'}}
                /> */}
                <TextField 
                  type='text'
                  label='Nombre de la empresa' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginTop: '2%', marginRight: '1.333%'}}
                  onChange={nameEmpresaChangeHandler}
                  value={jsonEmpresaData.nameEmpresa}
                />
                {/* <TextField 
                  type='text'
                  label='Prima mínima de riesgo %' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginRight: '1.333%', marginTop: '2%'}}
                />
                <TextField 
                  type='text'
                  label='Inicio relación laboral' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginRight: '1.333%', marginTop: '2%'}}
                />
                <TextField 
                  type='text'
                  label='Salario bruto mensual' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginTop: '2%'}}
                /> */}
                {/* <TextField 
                  type='text'
                  label='Prima vacacional %' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginTop: '2%'}}
                />
                <TextField 
                  type='text'
                  label='Impuesto sobre nómina %' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginLeft: '1.333%', marginRight: '1.333%', marginTop: '2%'}}
                />
                <TextField 
                  type='text'
                  label='Costo adicional por empleado' 
                  color='primary' 
                  variant='filled' 
                  sx={{width: '24%', marginTop: '2%', marginRight: '1.333%'}}
                /> */}
                <FormControl fullWidth sx={{width:'18%', marginTop: '2%', marginRight: '1.333%'}}>
                  <InputLabel 
                    id='grado-select-small-label' 
                    sx={{width: '100%'}}
                  >
                    Base de cálculo
                  </InputLabel>
                  <Select  
                    labelId='grado-select-small-label'
                    id='grado-select-small'
                    label='Grado'
                    onChange={baseCalculoChangeHandler}
                    value={jsonEmpresaData.baseCalculo}
                  >
                    <MenuItem value='Sueldo bruto'>
                      Sueldo bruto
                    </MenuItem>
                    <MenuItem value='Sueldo neto'>
                      Sueldo neto
                    </MenuItem>
                  </Select>    
                </FormControl> 
                <TextField 
                  type='text'
                  label='Prima de riesgo' 
                  color='primary' 
                  variant='filled' 
                  onChange={primaRiesgoChangeHandler}
                  value={jsonEmpresaData.primaRiesgo}
                  sx={{width: '18%', marginTop: '2%', marginRight: '1.333%'}}
                  />
                <TextField 
                  type='text'
                  label='Costo de operación' 
                  color='primary' 
                  variant='filled' 
                  onChange={costoOperacionChangeHandler}
                  value={jsonEmpresaData.costoOperacion}
                  sx={{width: '17.3%', marginTop: '2%', marginRight: '1.333%'}}
                />
                <TextField 
                  type='text'
                  label='UMA' 
                  color='primary' 
                  variant='filled' 
                  onChange={umaChangeHandler}
                  value={jsonEmpresaData.uma}
                  sx={{width: '17.3%', marginTop: '2%'}}
                />
                <FormControl fullWidth sx={{width:'24%', marginRight: '1.333%', marginTop: '2%'}}>
                  <InputLabel 
                    id='grado-select-small-label' 
                    sx={{width: '100%'}}
                  >
                    Periodo fiscal
                  </InputLabel>
                  <Select  
                    labelId='grado-select-small-label'
                    id='grado-select-small'
                    label='Grado'
                    onChange={periodoFiscalChangeHandler}
                    value={jsonEmpresaData.periodoFiscal}
                  >
                    <MenuItem value='2023'>
                      2023
                    </MenuItem>
                    <MenuItem value='2024'>
                      2024
                    </MenuItem>
                  </Select>    
                </FormControl> 
                <FormControl fullWidth sx={{width:'24%', marginRight: '1.333%', marginTop: '2%'}}>
                  <InputLabel 
                    id='grado-select-small-label' 
                    sx={{width: '100%'}}
                  >
                    Tipo de periodo
                  </InputLabel>
                  <Select  
                    labelId='grado-select-small-label'
                    id='grado-select-small'
                    label='Grado'
                    onChange={tipoPeriodoChangeHandler}
                    value={jsonEmpresaData.tipoPeriodo}
                  >
                    <MenuItem value='Mensual'>
                      Mensual
                    </MenuItem>
                    <MenuItem value='Quincenal'>
                      Quincenal
                    </MenuItem>
                    <MenuItem value='Semanal'>
                      Semanal
                    </MenuItem>
                    <MenuItem value='Diario'>
                      Diario
                    </MenuItem>
                    <MenuItem value='10 días'>
                      10 días
                    </MenuItem>
                  </Select>    
                </FormControl> 
                <FormControl fullWidth sx={{width:'24%', marginRight: '1.333%', marginTop: '2%'}}>
                  <InputLabel 
                    id='grado-select-large-label' 
                    sx={{width: '100%'}}
                  >
                    Distribución del cálculo
                  </InputLabel>
                  <Select  
                    labelId='grado-select-small-label'
                    id='grado-select-small'
                    label='Grado'
                    onChange={planSindicalChangeHandler}
                    value={jsonEmpresaData.planSindical}
                  >
                    <MenuItem value='Empresa 60% - Sindicato 40%'>
                      Empresa 60% - Sindicato 40%
                    </MenuItem>
                    <MenuItem value='Empresa 70% - Sindicato 30%'>
                      Empresa 70% - Sindicato 30%
                    </MenuItem>
                    <MenuItem value='Empresa 80% - Sindicato 20%'>
                      Empresa 80% - Sindicato 20%
                    </MenuItem>
                    <MenuItem value='Empresa 90% - Sindicato 10%'>
                      Empresa 90% - Sindicato 10%
                    </MenuItem>
                    {/* <MenuItem value='Personalizado'>
                      Personalizado
                    </MenuItem> */}
                  </Select>    
                </FormControl> 
                <FormControl fullWidth sx={{width:'24%', marginTop: '2%'}}>
                  <InputLabel 
                    id='grado-select-small-label' 
                    sx={{width: '100%'}}
                  >
                    Esquema de distribución
                  </InputLabel>
                  <Select  
                    labelId='grado-select-small-label'
                    id='grado-select-small'
                    label='Grado'
                    onChange={esquemaChangeHandler}
                    value={jsonEmpresaData.esquema}
                  >
                    <MenuItem value='Indemnización'>
                      Indemnización
                    </MenuItem>
                    {/* <MenuItem value='Clase II'>
                      Pensión
                    </MenuItem>
                    <MenuItem value='Clase II'>
                      Pensión e indemnización
                    </MenuItem> */}
                  </Select>    
                </FormControl> 
                {/* <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '2%'}}>
                  <Button
                    variant='contained'
                    color='success'
                    sx={{borderRadius: 8}}
                    >
                    <Typography sx={{fontSize: 18, fontWeight: 500}}>
                      Calcular
                    </Typography>
                  </Button>
                </Box> */}
                {/* <Box sx={{borderRadius:'10px', display:'flex', justifyContent:'center'}}> */}
                <Box sx={{display: 'flex', justifyContent: 'end', marginTop: '2%'}}>
                  <Button
                    color='success'
                    variant='contained'
                    onClick={() => { window.location.href = 'https://xmex-media.nyc3.digitaloceanspaces.com/pagina_publica/documentos/templateNomina.xlsx'; }}
                    startIcon={<DownloadIcon/>}
                    style={{color: '#FFFFFF'}}
                    sx={{borderRadius: 3}}
                  > 
                    <Typography sx={{color: '#ffff'}}>
                      Template
                    </Typography>
                  </Button>
                  <Button 
                    component="label"
                    variant='contained'
                    type="file"
                    accept='.xlsx, .xls'
                    onChange={handleFileChange}
                    disabled={disabledCargarExcel}
                    startIcon={<CloudUploadIcon sx={{color: '#ffff'}}/>}
                    sx={{borderRadius: 3, marginLeft: '2%'}}
                  > 
                    <Typography sx={{color: '#ffff'}}>
                      Cargar excel
                    </Typography>
                    <VisuallyHiddenInput accept='.xlsx, .xls' type='file'/>
                  </Button>
                  <Button 
                    variant='contained'
                    onClick={handleUploadClick}
                    disabled={!file}
                    sx={{marginLeft: '2%', borderRadius: 3, marginRight: '2%'}}
                  >
                    <Typography sx={{color: '#ffff'}}>
                      Calcular
                    </Typography>
                  </Button>
                  <PDFDownloadLink
                    document={
                      <ReporteNominaPDF
                        nameEmpresa={jsonEmpresaData.nameEmpresa}
                        tipoPeriodo={jsonEmpresaData.tipoPeriodo}
                        periodoFiscal={jsonEmpresaData.periodoFiscal}
                        esquema={jsonEmpresaData.esquema}
                        planSindical={jsonEmpresaData.planSindical}
                        baseCalculo={jsonEmpresaData.baseCalculo}

                        sueldoBaseNaranjaPorcentajeEmpresaLey={jsonEmpresaData.sueldoBaseNaranjaPorcentajeEmpresaLey}
                        indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato={jsonEmpresaData.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato}

                        sumatoriaSat={jsonEmpresaData.sumatoriaSat}
                        sumatoriaImss={jsonEmpresaData.sumatoriaImss}
                        sumatoriaImpNomina={jsonEmpresaData.sumatoriaImpNomina}
                        sumatoriaImssEmpresa={jsonEmpresaData.sumatoriaImssEmpresa}
                        sumatoriaRcv={jsonEmpresaData.sumatoriaRcv}
                        sumatoriaInfonavit={jsonEmpresaData.sumatoriaInfonavit}
                        sumatoriaIsr={jsonEmpresaData.sumatoriaIsr}
                        sumatoriaSueldos={jsonEmpresaData.sumatoriaSueldos}
                        sumatoriaSueldosBase={jsonEmpresaData.sumatoriaSueldosBase}
                        sumatoriaCTA={jsonEmpresaData.sumatoriaCTA}
                        // sumatoriaCTA={(jsonEmpresaData.sumatoriaCTApoyoSindicalImss + jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa + jsonEmpresaData.sumatoriaCTApoyoSindicalSat + jsonEmpresaData.sumatoriaCTApoyoSindicalIsr + jsonEmpresaData.sumatoriaCTApoyoSindicalRcv)}

                        sumatoriaCTApoyoSindicalImss={jsonEmpresaData.sumatoriaCTApoyoSindicalImss}
                        sumatoriaCTApoyoSindicalImssEmpresa={jsonEmpresaData.sumatoriaCTApoyoSindicalImssEmpresa}
                        sumatoriaCTApoyoSindicalSat={jsonEmpresaData.sumatoriaCTApoyoSindicalSat}
                        sumatoriaCTApoyoSindicalIsr={jsonEmpresaData.sumatoriaCTApoyoSindicalIsr}
                        sumatoriaCTApoyoSindicalRcv={jsonEmpresaData.sumatoriaCTApoyoSindicalRcv}
                        sumatoriaCTAS={jsonEmpresaData.sumatoriaCTAS}
                        // columna naranja
                        psIsrEmpleado={jsonApoyoSindical.isrEmpleado}
                        psImssEmpleado={jsonApoyoSindical.imssEmpleado}
                        psImpuestoNomina={jsonApoyoSindical.impuestoSobreNomina}
                        psImssEmpresa={jsonApoyoSindical.imssEmpresa}
                        psRcv={jsonApoyoSindical.rcv}
                        psInfonavit={jsonApoyoSindical.infonavit}
                        psTotal={jsonApoyoSindical.total}
                        // columna verde
                        restaIsr={jsonAhorro.isrEmpleado}
                        restaImss={jsonAhorro.imssEmpleado}
                        restaImpuestoNomina={jsonAhorro.impuestoSobreNomina}
                        restaImssEmpresa={jsonAhorro.imssEmpresa}
                        restaRcv={jsonAhorro.rcv}
                        restaInfonavit={jsonAhorro.infonavit}
                        restaTotal={jsonAhorro.total}

                        // plan sindical
                        indemnizacionRiesgoLaboral={jsonEmpresaData.indemnizacionRiesgoLaboralNaranjaPorcentajeSindicato}
                        costoOperacion={jsonEmpresaData.costoOperacion}
                        resultadoPorcentajeEmpresa={jsonEmpresaData.resultadoPorcentajeEmpresa}
                        resultadoPorcentajeSindicato={jsonEmpresaData.resultadoPorcentajeSindicato}
                        costoOperacionMensual={jsonEmpresaData.costoOperacionMensual}
                        costoOperacionAnual={jsonEmpresaData.costoOperacionAnual}

                        ahorroReal={jsonEmpresaData.ahorroReal}
                        ahorroRealAnual={jsonEmpresaData.ahorroRealAnual}
                      />
                    }
                    fileName={jsonEmpresaData.nameEmpresa + '_' + jsonEmpresaData.periodoFiscal + '.pdf'}
                  >
                    <Button
                      color='success'
                      variant='contained'
                      disabled={jsonEmpresaData.disBtnReporte}
                      startIcon={<DownloadIcon/>}
                      sx={{borderRadius: 3}}
                      style={{color: '#FFFFFF'}}
                    >
                      <Typography>
                        REPORTE
                      </Typography>
                    </Button>
                  </PDFDownloadLink>
                  {/* <Button
                    color='success'
                    variant='contained'
                    disabled={jsonEmpresaData.disBtnReporte}
                    startIcon={<DownloadIcon/>}
                    // onClick={this.changeHandleClickDownloadExcel}
                    onClick={handleClickEmpleado}
                    sx={{borderRadius: 3, marginLeft: '2%'}}
                    style={{color: '#FFFFFF'}}
                  >
                    <Typography>
                      EMPLEADOS
                    </Typography>
                  </Button> */}
                  <JsonToExcel 
                    jsonData={jsonDataExcel} 
                    fileName={jsonEmpresaData.nameEmpresa + "_" + jsonEmpresaData.periodoFiscal}
                    disabled={jsonEmpresaData.disBtnReporte}
                  />
                </Box>
              </Box>
            </Container>
          </Paper>
        </Box>
      </Grid>
    </Grid>
  )
}
