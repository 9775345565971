import React, {useContext} from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminContext, { AdminContextProvider } from './context/AdminContext';
import IdContext, { IdContextProvider } from './context/xmex/IdContext';

// ************************************* XMEX *************************************
// IMPORTACION PUBLIC PAGE
import { PaginaPublica } from './templates/paginaPublica/PaginaPublica';
// IMPORTACION 
import { PreAfiliacion } from './templates/afiliacion/PreAfiliacion';
import { FirmaAfiliacion } from './templates/afiliacion/FirmaAfiliacion';
import { Afiliacion } from './templates/afiliacion/Afiliacion';
import { AfiliacionExitosa } from './templates/afiliacion/AfiliacionExitosa';
import { LoginXmex } from './templates/login/LoginXmex';
import { DashAltaEmpresas } from './templates/admin/DashAltaEmpresas';
import { DashNomina } from './templates/admin/DashNomina';
import { DashAfiliados } from './templates/admin/DashAfiliados';
import { DashConfig } from './templates/admin/DashConfig';
import { Beneficios2 } from './components/xmex/paginaPublica/Beneficios';



// ************************************* SPM **************************************
//IMPORTACCION DE PUBLIC PAGE
import { PublicPage } from "./components/nom35/publicpage/PublicPage";
import { Politica_priv } from './components/nom35/publicpage/Politica_priv';
import { Aviso_legal } from './components/nom35/publicpage/Aviso_legal';
//IMPORTACCION DE CUISTIONARIOS
import { Login } from "./components/nom35/login/Login";
import { Template_Cuestionario11 } from "./components/nom35/cuestionario/Template_Cuestionario11";
import { Template_Cuestionario12 } from "./components/nom35/cuestionario/Template_Cuestionario12";
import { Template_Cuestionario13 } from "./components/nom35/cuestionario/Template_Cuestionario13";
import { Template_Cuestionario14 } from "./components/nom35/cuestionario/Template_Cuestionario14";
import { Template_Cuestionario21 } from "./components/nom35/cuestionario/Template_Cuestionario21";
import { Template_Cuestionario22 } from "./components/nom35/cuestionario/Template_Cuestionario22";
import { Template_Cuestionario23 } from "./components/nom35/cuestionario/Template_Cuestionario23";
import { Template_Cuestionario24 } from "./components/nom35/cuestionario/Template_Cuestionario24";
import { Template_Cuestionario25 } from "./components/nom35/cuestionario/Template_Cuestionario25";
import { Template_Cuestionario26 } from "./components/nom35/cuestionario/Template_Cuestionario26";
import { Template_Cuestionario_Condicional21 } from "./components/nom35/cuestionario/Template_Cuestionario_Condicional21";
import { Template_Cuestionario_Clientes21 } from "./components/nom35/cuestionario/Template_Cuestionario_Clientes21";
import { Template_Cuestionario_Condicional22 } from "./components/nom35/cuestionario/Template_Cuestionario_Condicional22";
import { Template_Cuestionario_Clientes22 } from "./components/nom35/cuestionario/Template_Cuestionario_Clientes22";
import { Template_Cuestionario31 } from "./components/nom35/cuestionario/Template_Cuestionario31";
import { Template_Cuestionario32 } from "./components/nom35/cuestionario/Template_Cuestionario32";
import { Template_Cuestionario33 } from "./components/nom35/cuestionario/Template_Cuestionario33";
import { Template_Cuestionario34 } from "./components/nom35/cuestionario/Template_Cuestionario34";
import { Template_Cuestionario35 } from "./components/nom35/cuestionario/Template_Cuestionario35";
import { Template_Cuestionario36 } from "./components/nom35/cuestionario/Template_Cuestionario36";
import { Template_Cuestionario37 } from "./components/nom35/cuestionario/Template_Cuestionario37";
import { Template_Cuestionario38 } from "./components/nom35/cuestionario/Template_Cuestionario38";
import { Template_Cuestionario39 } from "./components/nom35/cuestionario/Template_Cuestionario39";
import { Template_Cuestionario310 } from "./components/nom35/cuestionario/Template_Cuestionario310";
import { Template_Cuestionario311 } from "./components/nom35/cuestionario/Template_Cuestionario311";
import { Template_Cuestionario312 } from "./components/nom35/cuestionario/Template_Cuestionario312";
import { Template_Cuestionario313 } from "./components/nom35/cuestionario/Template_Cuestionario313";
import { Template_Cuestionario314 } from "./components/nom35/cuestionario/Template_Cuestionario314";
import { Template_Cuestionario315 } from "./components/nom35/cuestionario/Template_Cuestionario315";
import { Template_Cuestionario316 } from "./components/nom35/cuestionario/Template_Cuestionario316";
import { Fin_Cuestionario } from "./components/nom35/cuestionario/Fin_Cuestionarios";
import { Homepage_trabajador } from "./components/nom35/homepage/Homepage_trabajador";
import { TerminosCondiciones } from './components/nom35/homepage/TerminosCondiciones';
import { MBI } from "./components/nom35/cuestionario/mbi/MBI";
// IMPORTACION DE ADMINISTRADOR
import { Generales } from './components/nom35/admin/generales/Generales';
import { Registro_admin } from './components/nom35/admin/registro_admin/Registro_admin';
import { Registro_empresa } from "./components/nom35/admin/registro_empresa/Registro_empresa";
import { Registro_empleados } from "./components/nom35/admin/registro_empleados/Registro_empleados";
import { Dashboard1_admin } from "./components/nom35/admin/dashboard1_admin/Dashboard1_admin";
import { Perfil_admin_dashboard1_admin } from "./components/nom35/admin/perfil_admin_dashboard1_admin/Perfil_admin_dashboard1_admin";
import { Perfil_empresa_dashboard1_admin } from "./components/nom35/admin/perfil_empresa_dashboard1_admin/Perfil_empresa_dashboard1_admin";
import { Licencia_dashboard1_admin } from './components/nom35/admin/licencia_dashboard1_admin/Licencia_dashboard1_admin';
import { Reportes_dashboard1_admin } from './components/nom35/admin/reportes_dashboard1_admin/Reportes_dashboard1_admin';
import { Registro_empleados_dashboard_admin } from './components/nom35/admin/registro_empleados_dashboard_admin/Registro_empleados_dashboard_admin';
// IMPORTACION DE EMPLEADO
import { Preregistro_empleado } from "./components/nom35/empleado/preregistro/Preregistro_empleado";
import { Preregistro_empleado_aviso } from "./components/nom35/empleado/aviso/Preregistro_empleado_aviso";
import { Registro_empleado } from "./components/nom35/empleado/registro/Registro_empleado";
// IMPORTACION DE ANALISTA
import { VistaGeneral_DashAnalista } from './components/nom35/analista/vista_general/VistaGeneral_DashAnalista';
import { Dashboard_analista } from './components/nom35/analista/dashboard_analista/Dashboard_analista';
import { DatosEncuestas_DashAnalista } from './components/nom35/analista/datos_encuestas/DatosEncuestas_DashAnalista';
import { AdministrarUsuarios_DashAnalista } from './components/nom35/analista/administrar_usuarios/AdministrarUsuarios_DashAnalista';
import { Registro_analistas } from './components/nom35/analista/registro_analistas/Registro_analistas';


function App() {

  const ctxAdmin = useContext(AdminContext);
  console.log(ctxAdmin);

  const ctxId = useContext(IdContext);
  console.log(ctxId);

  return (
    <Router>
      <AdminContextProvider>
        <IdContextProvider>
          <div>
            <Routes>
              {/* ************************************ XMEX ************************************ */}
              {/* PUBLIC PAGE */ }
              <Route path={"/"} exact element={<PaginaPublica/>}/>
              {/* TEMPLATE DE PREAFILIACION */}
              <Route path='/preAfiliacion' exact element={<PreAfiliacion/>}/>
              {/* TEMPLATE FIRMA DE AFILIACION */}
              <Route path='/firmaDeAfiliacion' exact element={<FirmaAfiliacion/>}/>
              {/* TEMPLATE DE AFILIACION */}
              <Route path='/afiliacion' exact element={<Afiliacion/>}/>
              {/* TEMPLATE DE AFILIACION EXITOSA */}
              <Route path='/afiliacion/afiliacionExitosa' exact element={<AfiliacionExitosa/>}/>
              {/* TEMPLATE DE LOGIN */}
              <Route path='/loginXmex' exact element={<LoginXmex/>}/>
              {/* TEMPLATE DE SUPER USER */}
              <Route path='/altaEmpresas' exact element={<DashAltaEmpresas/>}/>
              <Route path='/nomina' exact element={<DashNomina/>}/>
              <Route path='/listado' exact element={<DashAfiliados/>}/>
              <Route path='/config' exact element={<DashConfig/>}/>
              <Route path='/beneficios' exact element={<Beneficios2/>}/>

              {/* ************************************ NOM35 ************************************ */}
              {/* PUBLIC PAGE */ }
              <Route path={"/nom35"} exact element={<PublicPage/>}/>
              <Route path={"/loginNom035"} exact element={<Login/>}/>
              <Route path={"/politica_priv"} exact element={<Politica_priv/>}/>
              <Route path={"/aviso_legal"} exact element={<Aviso_legal/>}/>
              {/* HOME PAGE TRABAJADOR */}
              <Route path={"/bienvenida"} element={<Homepage_trabajador/>}/>
              <Route path={"/terminosCondiciones"} element={<TerminosCondiciones/>}/>
              {/* CUESTIONARIO ACONTECIMIENTOS TRAUMATICOS SEVEROS */}
              <Route path="/cuestionario_101" element={<Template_Cuestionario11/>}/>
              <Route path="/cuestionario_102" element={<Template_Cuestionario12/>}/>
              <Route path="/cuestionario_103" element={<Template_Cuestionario13/>}/>
              <Route path="/cuestionario_104" element={<Template_Cuestionario14/>}/>
              {/* CUESTIONARIO RIESGO PSICOSOCIAL */}
              <Route path="/cuestionario_201" element={<Template_Cuestionario21/>}/>
              <Route path="/cuestionario_202" element={<Template_Cuestionario22/>}/>
              <Route path="/cuestionario_203" element={<Template_Cuestionario23/>}/>
              <Route path="/cuestionario_204" element={<Template_Cuestionario24/>}/>
              <Route path="/cuestionario_205" element={<Template_Cuestionario25/>}/>
              <Route path="/cuestionario_206" element={<Template_Cuestionario26/>}/>
              <Route path="/cuestionario_207" element={<Template_Cuestionario_Condicional21/>}/>
              <Route path="/cuestionario_208" element={<Template_Cuestionario_Clientes21/>}/>
              <Route path="/cuestionario_209" element={<Template_Cuestionario_Condicional22/>}/>
              <Route path="/cuestionario_210" element={<Template_Cuestionario_Clientes22/>}/>
              {/* CUESTIONARIO ENTORNO ORGANIZACIONAL */}
              <Route path="/cuestionario_301" element={<Template_Cuestionario31/>}/>
              <Route path="/cuestionario_302" element={<Template_Cuestionario32/>}/>
              <Route path="/cuestionario_303" element={<Template_Cuestionario33/>}/>
              <Route path="/cuestionario_304" element={<Template_Cuestionario34/>}/>
              <Route path="/cuestionario_305" element={<Template_Cuestionario35/>}/>
              <Route path="/cuestionario_306" element={<Template_Cuestionario36/>}/>
              <Route path="/cuestionario_307" element={<Template_Cuestionario37/>}/>
              <Route path="/cuestionario_308" element={<Template_Cuestionario38/>}/>
              <Route path="/cuestionario_309" element={<Template_Cuestionario39/>}/>
              <Route path="/cuestionario_310" element={<Template_Cuestionario310/>}/>
              <Route path="/cuestionario_311" element={<Template_Cuestionario311/>}/>
              <Route path="/cuestionario_312" element={<Template_Cuestionario312/>}/>
              <Route path="/cuestionario_313" element={<Template_Cuestionario313/>}/>
              <Route path="/cuestionario_314" element={<Template_Cuestionario314/>}/>
              <Route path="/cuestionario_315" element={<Template_Cuestionario315/>}/>
              <Route path="/cuestionario_316" element={<Template_Cuestionario316/>}/>
              {/* CUESTIONARIO BURNOUT MASLACH INVENTORY */}
              <Route path="/cuestionario_401" element={<MBI/>}/>
              {/* FIN CUESTIONARIOS */}
              <Route path="/cuestionario_fin" element={<Fin_Cuestionario/>}/>
              {/* PERFIL DE ADMINISTRADOR */}
              <Route path="/generales_administrador" element={<Generales/>}/>
              <Route path="/registro_administrador" element={<Registro_admin/>}/>
              <Route path="/registro_empresa" element={<Registro_empresa/>}/>
              <Route path="/registro_empleados" element={<Registro_empleados/>}/>
              <Route path="/dashboard_admin" element={<Dashboard1_admin/>}/>
              <Route path="/perfil-admin_admin" element={<Perfil_admin_dashboard1_admin/>}/>
              <Route path="/perfil-empresa_admin" element={<Perfil_empresa_dashboard1_admin/>}/>
              <Route path="/registro-empleados_admin" element={<Registro_empleados_dashboard_admin/>}/>
              <Route path="/licencia_admin" element={<Licencia_dashboard1_admin/>}/>
              <Route path="/reportes_admin" element={<Reportes_dashboard1_admin/>}/>
              {/* PERFIL DE EMPLEADO */}
              <Route path="/preregistro_empleado" element={<Preregistro_empleado/>}/>
              <Route path="/registro_empleado_aviso" element={<Preregistro_empleado_aviso/>}/>
              <Route path="/registro_empleado" element={<Registro_empleado/>}/>
              {/* PERFIL ANALISTA */}
              <Route path="/analista_vista-general" element={<VistaGeneral_DashAnalista/>}/>
              <Route path="/dashboard_analista" element={<Dashboard_analista/>}/>
              <Route path="/analista_datos-encuestas" element={<DatosEncuestas_DashAnalista/>}/>
              <Route path="/analista_administrar-usuarios" element={<AdministrarUsuarios_DashAnalista/>}/>
              <Route path="/analista_registrar-analistas" element={<Registro_analistas/>}/>
            </Routes>
          </div>
        </IdContextProvider>
      </AdminContextProvider>
    </Router>
  );
};

export default App;
