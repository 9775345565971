import React, { useContext } from 'react';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import IdContext from '../../../context/xmex/IdContext';
import { emailAutomatico, getToken } from '../../../utils/xmex/services';
import Cookies from 'universal-cookie';

export const Btn_afiliarse = (props) => {

  const cookies = new Cookies();
  
  const ctx = useContext(IdContext);
  const clickHandlerAfiliarse = async () => {
    if(props.btnLogInIniciar === 'si'){
      await getToken();
    }
    if(props.test != 'si'){
    console.log(ctx.idAfiliado);
    if(ctx.idAfiliado > 0){
      props.json.id = ctx.idAfiliado;
      console.log('id: ' + props.json.id)
    }
    props.update(props.json).then((data) => {
      console.log(data);
      if(ctx.idAfiliado === 0){
        ctx.updateAfiliado(data.id);
        console.log('id: ' + data.id);
        console.log(ctx.idAfiliado);
      }
      if (props.signatureEmailName === 'si'){
        props.setFirmaBase64(data.img_firma);
        props.setIdAfiliado(data.id);
        props.btnCredencialDisabled(false);
        
        //Actializo el estado
        // setEmailAuto((prevEmailAuto) => ({
        //   ...prevEmailAuto,
        //   usuario: {
        //     ...prevEmailAuto.usuario,
        //     email: data.email
        //   }
        // }));
        emailAutomatico({
          usuario: {
            // nombre: props.nameAfiliado,
            nombre: cookies.get('cookieNombreCompleto'),
            email: cookies.get('cookieEmailAfiliacion')
          }
        });
        console.log("name: ", + props.nameAfiliado);
        console.log("email: ", + cookies.get('cookieEmailAfiliacion'));
        emailAutomatico({
          usuario: {
            nombre: "Jarrett Ademir Vizuet JSON",
            email: "jarrett_vizuet@outlook.com"
          }
        });

        // POST al metodo para el correo automatico
        // emailAutomatico(emailAuto)
      }
    });
  }
  };

  return (
      <Button 
        color='primary' 
        variant='contained'
        sx={{borderRadius: 8}}
        onClick={clickHandlerAfiliarse}
        disabled={props.disabled}
      >
        <Link to={props.link} style={{textDecoration: 'none'}}>
            <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: 'white'}}>
                {props.label}
            </Typography>
        </Link>     
    </Button>    
  );
};
