import React, { Fragment } from 'react'
import { AppBar, Toolbar, Box, useTheme, useMediaQuery, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const src_url = 'https://xmex-media.nyc3.digitaloceanspaces.com/';

export const NavBar_Login = () => {
    
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Fragment>
      {isSmallScreen &&
            <AppBar sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to='/'>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '6ch'}}
                        />
                    </Link>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Typography sx={{fontSize: 15, fontWeight: 500}} style={{color: '#303030'}}>
                            INICIO
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        }
        {isMediumScreen &&
            <AppBar sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to='/'>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '7ch'}}
                        />
                    </Link>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: '#303030'}}>
                            INICIO
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        }
        {isLargeScreen &&
            <AppBar sx={{paddingTop: 1, paddingBottom: 1,background: '#ffff'}}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to='/'>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{width: '7ch'}}
                        />
                    </Link>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Typography sx={{fontSize: 18, fontWeight: 500}} style={{color: '#303030'}}>
                            INICIO
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        }
        {isExtraLargeScreen &&
            <AppBar sx={{ paddingTop: 1, paddingBottom: 1, background: '#ffff' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to='/'>
                        <img 
                            src={src_url + 'logo_spm.png'}
                            style={{ width: '7ch' }}
                        />
                    </Link>
                    <Link to='/' style={{textDecoration: 'none'}}>
                        <Typography sx={{fontSize: 20, fontWeight: 500}} style={{color: '#303030'}}>
                            INICIO
                        </Typography>
                    </Link>
                </Toolbar>
            </AppBar>
        }
    </Fragment>
  )
}
