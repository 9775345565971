import React from 'react'
import ResponsiveDrawer from '../../components/xmex/afiliados/MenuAfiliados'

export const DashAfiliado = () => {
  return (
    <div>
        <ResponsiveDrawer/>
    </div>
  )
}
